import './App.css';
import Home from './pages/Home';
import PatientHealthProfile from './pages/PatientHealthProfile';
import PatientPrescription from './pages/PatientPrescription';
import ConsultationHistoryList from './pages/ConsultationHistoryList';
import ConsultationHistoryDetails from './pages/ConsultationHistoryDetails';
import MyDoctors from './pages/MyDoctors';
import MedicalRecords from './pages/MedicalRecords';
import AddRecords from './pages/AddRecords';
import EditHealthProfile from './pages/EditHealthProfile';
import EditLifeStyle from './pages/EditLifeStyle';
import Doctors from './pages/Doctors';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ValidateMobile from './pages/ValidateMobile';
import EditProfile from './pages/EditProfile';
import PrintPrescription from './pages/PrintPrescription';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Editvitals from './pages/Editvitals';
import EditFamilyHistory from './pages/EditFamilyHistory';
import EditPhoto from './pages/EditPhoto';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import ViewMedicalRecords from './pages/ViewMedicalRecords';
import FileReader from './pages/FileReader';
import EditVaccination from './pages/EditVaccination';
import LandingPage from './pages/LandingPage';
import ContactUs from './pages/ContactUs';
import Tickets from './pages/Tickets';
import ViewTicket from './pages/ViewTicket';
import SelectMedicalVitals from './pages/SelectMedicalVitals';
import Spinner from './components/Spinner';
import CustomEditVitals from './pages/CustomEditVitals';
import CheckinForm from './pages/CheckinForm';
import PatientFeedback from './pages/PatientFeedback';
import './css/Global.css';

//global.baseUrl = 'https://testemr-api-php.cb-dev.in';
//global.baseUrl = 'https://emr-api.healthconnectpro.in';

global.OTPValidation = 'false';


function App() {
  return (
    <RecoilRoot>
      <div className="App">

        <Router>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/editVitals" element={<Editvitals />} />
            <Route path="/PatientHealthProfile" element={<PatientHealthProfile />} />
            <Route path="/MedicalRecords" element={<MedicalRecords />} />
            <Route path="/ConsultationHistoryList" element={<ConsultationHistoryList />} />
            <Route path="/ConsultationHistoryDetails" element={<ConsultationHistoryDetails />} />
            <Route path="/MyDoctors" element={<MyDoctors />} />
            <Route path="/AddRecords" element={<AddRecords />} />
            <Route path="/ViewMedicalRecors" element={<ViewMedicalRecords />} />
            <Route path="/EditHealthProfile" element={<EditHealthProfile />} />
            <Route path="/EditLifeStyle" element={<EditLifeStyle />} />
            <Route path="/EditVaccination" element={<EditVaccination />} />
            <Route path="/Doctors" element={<Doctors />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/ValidateMobile" element={<ValidateMobile />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/PrintPrescription" element={<PrintPrescription />} />
            <Route path="/EditFamilyHistory" element={<EditFamilyHistory />} />
            <Route path="/EditPhoto" element={<EditPhoto />} />
            <Route path="/FileReader" element={<FileReader />} />
            <Route path="/ViewPrescription" element={<ConsultationHistoryDetails />} />
            <Route path="/AddTicket" element={<ContactUs />} />
            <Route path="/Tickets" element={<Tickets />} />
            <Route path="/ViewTicket" element={<ViewTicket />} />
            <Route path="/SelectVitals" element={<SelectMedicalVitals />} />
            <Route path="/spinner" element={<Spinner />} />
            <Route path="/customEditVitals" element={<CustomEditVitals />} />
            <Route path="/fileReader" element={<FileReader />} />
            <Route path="/UGF0aWVudEZlZWRiYWNr/:search" element={<PatientFeedback />} />
            <Route path="/CheckinForm" element={<CheckinForm />} />
          </Routes>
        </Router>
      </div>

    </RecoilRoot>
  );

}

export default App;
