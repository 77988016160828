// src/Scanner.js
import React, { useEffect, useRef, useState } from "react";

const Scanner = ({ base64, height = 200, width = 200, onImageLoaded }) => {
  const loadScanner = () => {
    function scan() {
      const scanner = new window.jscanify();
      const scanned = scanner.extractPaper(image, height, width);
      const base64Image = scanned.toDataURL("image/png");
      onImageLoaded(base64Image);
    }
    const image = window.document.getElementById("image");
    image.onload = scan;

    if (image.complete) {
      scan();
    }
  };
  useEffect(() => {
    loadScanner();
  }, []);

  return (
    <div>
      {base64 ? (
        <img
          style={{ display: "none" }}
          src={base64}
          crossorigin="anonymous"
          id="image"
        />
      ) : (
        <p>Please provide a base64 image string.</p>
      )}
    </div>
  );
};

export default Scanner;
