import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useLocation } from "react-router";
import documents from "../assets/images/documents.png";
import { useNavigate } from "react-router-dom";
import back from "../assets/images/back.png";
import deleteicon from "../assets/images/delete-icon.jpg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FcImageFile } from "react-icons/fc";
import { AiFillFilePdf } from "react-icons/ai";
import plus from "../assets/images/plus.png";
import $ from "jquery";
import Spinner from "../components/Spinner";
const moment = require("moment");
const ViewMedicalRecords = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoFile, setIsNoFile] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleteDoc, setIsDeleteDoc] = useState(false);
  const [uploadedDocId, setUploadedDocId] = useState("");
  const [refreshUploadedDoc, setRefreshUploadedDoc] = useState(false);
  const [folderDetails, setFolderDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [img, setImg] = useState("");
  const [fileType, setFileType] = useState("");

  let id = location.state.id;
  let patientUniqueId = localStorage.getItem("patientUniqueId");
  console.log(patientUniqueId);
  console.log(id);

  useEffect(() => {
    setIsOpenCollapse(true);
    setIsLoading(true);
    setUploadedFile([]);
    Axios.get(
      `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/get_single_uploaded_file_by_folder_id?folder_id=${id}`
    ).then((response) => {
      console.log(" uploaded file success response ", response.data);
      setIsLoading(false);
      setSpinner(false);
      if (response.data.status == "1") {
        setFolderDetails(response.data.response);
      }
    });
    Axios.get(
      `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/get_uploaded_file_by_folder_id?folder_id=${id}`
    ).then((response) => {
      console.log(" uploaded file success response ", response.data);
      setIsLoading(false);
      setSpinner(false);
      if (response.data.status == "1") {
        if (response.data.response.length > 0) {
          setIsNoFile(false);
          setUploadedFile(response.data.response);
        } else {
          setIsNoFile(true);
        }
      }
    });
  }, []);
  const backHandler = () => {
    global.OTPValidation = "true";
    navigate("/MedicalRecords");
  };
  const openNewTabHandler = (file, fileType) => {
    setFileType(fileType);
    const sendFiles = { file, fileType, patientUniqueId, id };
    navigate("/fileReader", { state: sendFiles });
  };
  const downloadPdfHandeler = (file, fileType) => {
    const sendFiles = { file, fileType, patientUniqueId, id };
    console.log(sendFiles);
    setFileType(fileType);
    navigate("/fileReader", { state: sendFiles });
  };
  const handleDeleteClickOpen = (id) => {
    setDeleteOpen(true);
    setUploadedDocId(id);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const deleteDocumentsHandler = (e) => {
    setIsDeleteDoc(true);
    e.preventDefault();
    let formdata = $(e.target);
    formdata = new FormData();
    formdata.append("id", uploadedDocId);

    $.ajax({
      type: "POST",
      url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/delete_documents?id=${uploadedDocId}`,
      data: formdata,
      processData: false,
      contentType: false,
      success(data) {
        console.log("deleted document response", data);
        // alert("Document deleted");
        setIsDeleteDoc(false);
        setDeleteOpen(false);
        setRefreshUploadedDoc(false);
        window.location.reload(false);
      },
      error: function (err) {
        console.log(err.responseText);
        setIsDeleteDoc(false);
        alert("Error has occured  ", err.responseText);
      },
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setImg("");
  };
  return (
    <>
      {loading || spinner ? (
        <Spinner />
      ) : (
        <>
          <div style={{ textAlign: "left" }}>
            <img
              src={back}
              style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }}
              onClick={backHandler}
            />
          </div>
          <p
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#A696FF",
              marginTop: -10,
            }}
          >
            Records in folder
          </p>
          <p
            style={{
              textAlign: "left",
              fontSize: 18,
              fontWeight: "600",
              marginLeft: 20,
              marginTop: 25,
            }}
          >
            {" "}
            {folderDetails.visiting_purpose} - {folderDetails.doctors_name} (
            {folderDetails.date}){" "}
          </p>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            {isLoading ? (
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "600",
                  color: "green",
                  textAlign: "left",
                  marginLeft: 10,
                }}
              >
                Loading...
              </p>
            ) : (
              <div style={{ overflowY: "scroll" }}>
                {isNoFile ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: 16,
                        textAlign: "left",
                        marginLeft: 120,
                        fontWeight: "bold",
                        marginTop: 90,
                      }}
                    >
                      No files found
                    </p>

                    <div
                      className="add-records"
                      onClick={() => navigate("/AddRecords", { state: id })}
                    >
                      <img
                        src={plus}
                        style={{
                          height: 22,
                          width: 30,
                          marginTop: 20,
                          textAlign: "center",
                        }}
                      />
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "600",
                          marginTop: 20,
                          width: 80,
                          textAlign: "left",
                        }}
                      >
                        Add Records
                      </p>
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: 15,
                        fontWeight: "600",
                        marginLeft: 20,
                      }}
                    >
                      Click the + button to add records
                    </p>
                    <p
                      onClick={() => navigate("/AddRecords", { state: id })}
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: 15,
                        color: "blue",
                        marginLeft: "12%",
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{ minWidth: 1, fontSize: 22 }}
                      >
                        +
                      </button>{" "}
                    </p>
                  </div>
                )}

                {uploadedFile.map((value) => {
                  return (
                    <>
                      <div
                        key={value.id}
                        style={{
                          height: "auto",
                          width: "auto",
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 10,
                          marginLeft: 20,
                          backgroundColor: "gray",
                          backgroundColor: "#F8F9F9",
                          borderRadius: 8,
                          marginRight: 10,
                          boxShadow: " 1px 1px 2px 2px #d4bebe",
                          borderColor: "white",
                          backgroundColor: "white",
                          marginBottom: 10,
                          paddingRight: 5,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "50%",
                          }}
                        >
                          {value.document_type == "application/pdf" ? (
                            <AiFillFilePdf
                              className="doc-image"
                              style={{
                                borderRadius: 10,
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: 5,
                              }}
                              size={35}
                              onClick={() => {
                                downloadPdfHandeler(
                                  value.file,
                                  value.document_type
                                );
                              }}
                            />
                          ) : (
                            <FcImageFile
                              className="doc-image"
                              style={{
                                borderRadius: 10,
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: 5,
                              }}
                              size={35}
                              onClick={() => {
                                openNewTabHandler(
                                  value.file,
                                  value.document_type
                                );
                              }}
                            />
                          )}

                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              marginLeft: 8,
                              marginTop: 15,
                            }}
                          >
                            {value.document_name}
                          </p>
                        </div>
                        <div style={{ width: "25%" }}>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              marginLeft: 15,
                              marginTop: 13,
                            }}
                          >
                            {value.create_date_time}
                          </p>
                        </div>
                        <div style={{ width: "25%" }}>
                          <img
                            src={deleteicon}
                            style={{
                              height: 30,
                              width: 30,
                              marginLeft: 40,
                              marginTop: 13,
                            }}
                            onClick={() => {
                              handleDeleteClickOpen(value.id);
                            }}
                          />
                        </div>
                        <Dialog
                          open={deleteOpen}
                          onClose={handleDeleteClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Are you sure you want to delete this document?"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description"></DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleDeleteClose}>Cancel</Button>
                            {isDeleteDoc == false ? (
                              <form
                                method="post"
                                onSubmit={(event) =>
                                  deleteDocumentsHandler(event)
                                }
                              >
                                <Button type="submit" autoFocus>
                                  Delete
                                </Button>
                              </form>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 15,
                                  fontWeight: "bold",
                                  marginLeft: 10,
                                  marginTop: -5,
                                }}
                              >
                                Deleting...
                              </p>
                            )}
                          </DialogActions>
                        </Dialog>
                      </div>
                    </>
                  );
                })}
              </div>
            )}

            {/* </Collapsible> */}
          </div>
        </>
      )}
    </>
  );
};

export default ViewMedicalRecords;
