import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from 'react-router';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import feedback from '../mainassets/feedback2.jpg'
const moment = require('moment');
const PatientFeedback = () => {
    const [overallRating, setOverallRating] = useState(0);
    const [listeningRating, setListeningRating] = useState(0);
    const [assessingRating, setAssessingRating] = useState(0);
    const [discussingRating, setDiscussingRating] = useState(0);
    const [comment, setComment] = useState('');
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const [isEmptyFields, setIsEmptyFields] = useState(false);
    const [isFormHide, setIsFormHide] = useState(false);
    const [docName, setDocName] = useState("");
    const [isSubmitted, setIsSubmitted] = useState("");
    const navigate = useNavigate();
    const params = useParams();
    console.log(params.search);
    let decodedstring = base64_decode(params.search);
    console.log("decodedstring ", decodedstring);


    let feedbackId = decodedstring.split("&")[0];
    console.log("feedbackId :", feedbackId);

    let docid = decodedstring.split("&")[1];
    console.log("docId :", docid);

    let date;
    date = decodedstring.split("&")[2];
    console.log("consultationDate :", date);
    let day, month, year;
    day = date.slice(0, 2);
    month = date.slice(2, 4);
    year = date.slice(4, 8);
    const handleCommentChange = (event) => {
        setComment(event.target.value);
        console.log(event.target.value);
    };
    const reasonChangeHandler = (event) => {
        setReason(event.target.value);
        console.log(event.target.value);
    }

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Feedback/get_is_submitted_feedback?feedback_id=${feedbackId}`
        ).then(
            (response) => {
                console.log(response.data.response);
                setIsSubmitted(response.data.response.is_submitted);
                if (response.data.response.is_submitted == 0) {
                    axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Doctors/get_doctor_name?doctorid=${docid}`
                    ).then(
                        (response) => {
                            console.log("doctor name", response.data.response.name);
                            setDocName(response.data.response.name);
                        }

                    ).catch((error) => {
                        console.log(error.data);
                    });
                }
            }

        ).catch((error) => {
            console.log(error.data);
        });


    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here, you can perform any action with the feedback data (e.g., sending it to a server).
        console.log('Rating:', overallRating);
        // console.log('Comment:', comment);
        // Reset the form
        // setRating(0);
        // setComment('');
        // axios.post("https://connectedbytes.in/emr-clinic-rest-api-php/api/Feedback/update_feedback", {
        //     headers: {
        //         'content-type': 'text/json'
        //     },
        //     id: 3,
        //     reason: reason,
        //     listening_to_you: listeningRating,
        //     assessing_your_condition: assessingRating,
        //     discussing_your_treatment: discussingRating,
        //     overall_rating: overallRating,
        //     comments: comment
        // })
        //     .then((response) => {
        //         console.log(response.data);

        //     });
        if (reason != '', listeningRating != '', assessingRating != '', discussingRating != '', overallRating != '', comment != '') {


            let formdata = $(e.target);
            formdata = new FormData();
            setIsEmptyFields(false);
            formdata.append('feedback_id', feedbackId);
            formdata.append('reason', reason);
            formdata.append('listening_to_you', listeningRating);
            formdata.append('assessing_your_condition', assessingRating);
            formdata.append('discussing_your_treatment', discussingRating);
            formdata.append('overall_rating', overallRating);
            formdata.append(' comments', comment);

            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Feedback/update_feedback`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log(data);
                    if (data.status == "1") {
                        setIsFormHide(true);
                        setMessage(" Thanks for your feedback");
                    }

                },
                error: function (err) {
                    console.log(err);
                    alert("sumission failed ", err);

                }
            });
        }
        else {
            e.preventDefault();
            setIsEmptyFields(true);
        }

    };


    const handleListeningStarClick = (selectedRating) => {
        setListeningRating(selectedRating);

    };
    const handleAssessingStarClick = (selectedRating) => {
        setAssessingRating(selectedRating);
    };
    const handleDiscussingStarClick = (selectedRating) => {
        setDiscussingRating(selectedRating);
    };
    const handleOverallStarClick = (selectedRating) => {
        setOverallRating(selectedRating);
    };

    return (
        <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
            {
                isSubmitted != "1" ?
                    <>
                        {
                            isFormHide == false ?

                                <div className="checkin-form">


                                    <div class="mx-0 mx-sm-auto">
                                        <div class="card">
                                            <div class="card-header bg-primary">
                                                <h5 class="card-title text-white mt-2" id="exampleModalLabel">Doctor Feedback Survey Form</h5>
                                            </div>
                                            <div class="card-body">
                                                {/* <i class="far fa-file-alt fa-4x mb-3 text-primary"></i> */}
                                                <img src={feedback} style={{ height: 90, width: 90 }} />
                                                <p>
                                                    <strong>Your opinion matters</strong>
                                                </p>
                                                <div class="text-center" style={{ marginTop: -10 }}>
                                                    <p>
                                                        Please leave your feedback survey below
                                                    </p>
                                                </div>
                                                <hr />
                                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginTop: -10 }}>
                                                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold', color: '#2C80FF', width: "37%" }}>Doctor Name : </p>
                                                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold', color: '#2C80FF', width: "50%" }}> {docName}</p>

                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginTop: -10 }}>
                                                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold', color: '#2C80FF', width: '37%' }}>Date of visit :  </p>
                                                    <p style={{ textAlign: 'left', fontSize: 14, fontWeight: 'bold', color: '#2C80FF', width: "50%" }}>{day}.{month}.{year}</p>
                                                </div>
                                                <form class="px-2" action="">
                                                    <p class="text-justify" ><strong>The reason you went to the doctor:</strong></p>
                                                    <div class="form-check d-flex mb-2">
                                                        <input value={"To seek the doctor's advice"} class="form-check-input" type="radio" name="exampleForm" id="radio3Example1" onChange={(event) => reasonChangeHandler(event)} />
                                                        <label class="form-check-label" for="radio3Example1">
                                                            To seek the doctors advice
                                                        </label>
                                                    </div>
                                                    <div class="form-check d-flex mb-2">
                                                        <input value={" For routine check-up"} class="form-check-input" type="radio" name="exampleForm" id="radio3Example2" onChange={(event) => reasonChangeHandler(event)} />
                                                        <label class="form-check-label" for="radio3Example2">
                                                            For routine check-up
                                                        </label>
                                                    </div>
                                                    <div class="form-check d-flex mb-2">
                                                        <input value={"To get treatment"} class="form-check-input" type="radio" name="exampleForm" id="radio3Example3" onChange={(event) => reasonChangeHandler(event)} />
                                                        <label class="form-check-label" for="radio3Example3">
                                                            To get treatment
                                                        </label>
                                                    </div>
                                                    <div class="form-check d-flex mb-3">
                                                        <input value={"Other"} class="form-check-input" type="radio" name="exampleForm" id="radio3Example4" onChange={(event) => reasonChangeHandler(event)} />
                                                        <label class="form-check-label" for="radio3Example4">
                                                            Other
                                                        </label>
                                                    </div>
                                                    <p class="text-justify"><strong>How good was your doctor on each of the following parameters?:</strong></p>
                                                    <ul className='list-group'>
                                                        <div >
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <label class="form-check-label" for="radio3Example4" style={{ textAlign: 'left', width: "50%" }}>
                                                                    Listening to you
                                                                </label>
                                                                <div style={{ width: "70%", textAlign: 'right', marginRight: -5 }}>
                                                                    {[1, 2, 3, 4, 5].map((star) => (
                                                                        <FaStar
                                                                            key={star}
                                                                            size={24}
                                                                            style={{ cursor: 'pointer', marginLeft: '5px', color: star <= listeningRating ? 'gold' : 'gray' }}
                                                                            onClick={() => handleListeningStarClick(star)}
                                                                        />
                                                                    ))}
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <label class="form-check-label" for="radio3Example4" style={{ textAlign: 'left', width: "58%" }}>
                                                                Assessing your condition
                                                            </label>
                                                            <div style={{ width: "70%", textAlign: 'right', marginRight: -10 }}>
                                                                {[1, 2, 3, 4, 5].map((star) => (
                                                                    <FaStar
                                                                        key={star}
                                                                        size={24}
                                                                        style={{ cursor: 'pointer', marginRight: '5px', color: star <= assessingRating ? 'gold' : 'gray' }}
                                                                        onClick={() => handleAssessingStarClick(star)}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <label class="form-check-label" for="radio3Example4" style={{ textAlign: 'left', width: "67%" }}>
                                                                Discussing your treatment
                                                            </label>
                                                            <div style={{ width: "70%", textAlign: 'right', marginRight: -10 }}>
                                                                {[1, 2, 3, 4, 5].map((star) => (
                                                                    <FaStar
                                                                        required
                                                                        key={star}
                                                                        size={24}
                                                                        style={{ cursor: 'pointer', marginRight: '5px', color: star <= discussingRating ? 'gold' : 'gray' }}
                                                                        onClick={() => handleDiscussingStarClick(star)}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>


                                                    </ul>
                                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                                        <p class="text-justify" style={{ textAlign: 'left', width: "45%" }}><strong>Overall rating on doctor:</strong></p>
                                                        <div style={{ width: "70%", textAlign: 'right', marginRight: -10 }} >
                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                <FaStar
                                                                    key={star}
                                                                    size={24}
                                                                    style={{ cursor: 'pointer', marginRight: '5px', color: star <= overallRating ? 'gold' : 'gray' }}
                                                                    onClick={() => handleOverallStarClick(star)}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>


                                                    <p class="text-justify"><strong>Would you like to share any comments and suggestions about your experience?</strong></p>


                                                    <div class="form-outline mb-4">
                                                        <textarea value={comment} class="form-control" id="form4Example3" rows="4" required onChange={(event) => handleCommentChange(event)}></textarea>

                                                    </div>
                                                    <div class="card-footer text-end">
                                                        <button type="button" class="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                                    </div>

                                                </form>

                                                {
                                                    isEmptyFields ?
                                                        <p className='text-danger'>All fields are required</p>
                                                        : null
                                                }
                                            </div>
                                            {/* <div class="card-footer text-end">
                            <button type="button" class="btn btn-primary" onClick={() => navigate("/PatientFeedbackDetails")}>Check Feedback</button>
                        </div> */}

                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            message != '' ?
                                <p style={{ fontSize: 16, fontWeight: 'bold' }} className='text-success'>{message}</p>
                                : null
                        }
                    </>
                    :
                    <div style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#FFFAFA', height: 100, width: 'auto', padding: 20, marginTop: 50 }}>
                        <p style={{ fontSize: 16, fontWeight: 'bold', marginTop: 10 }} className='text-success'>Feedback already submitted</p>
                    </div>

            }


        </div>
    );
};

export default PatientFeedback;
